import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ExportExcel from "../utils/ExportExcel";
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useAuth0 } from "@auth0/auth0-react";
import setBodyColor from '../setBodyColor'
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import "chartjs-plugin-datalabels";
import { Link } from "react-router-dom";
import NavBar_MyMo from "./components/NavBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

let values_adquisition= [true, false, false, false];

const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

function average(array) {
  if (array !== undefined) {
    return array.reduce((a, b) => a + b) / array.length;
  }
  else {
    return 0
  }
}


function numberWithCommas(x) {
  if (typeof x !== "undefined") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  }
  else {
    return 0
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      general_insights_api: null,
      month_parking_overview: null,
      daily_parking_overview: null,
      graph_param: 'week',
      graph_parking_usage: null,
      file: null,
      message: '',
      conversion_rates: [],
      adquisition_params: 'color'
      };
  }

  async componentDidMount() {
    if (this.props.auth0) {
      const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.setState({ token });
        this.getGeneralInsights(token);
        this.getParkingInsights(token);
        this.getParkingUsage(token, this.state.graph_param);
        this.getOvUsage(token, values_adquisition);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }
  }

  getDayUnixTimestamps() {
    const now = new Date();

    // Get the start of the day
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const startOfDayUnix = startOfDay.getTime();

    // Get the end of the day
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const endOfDayUnix = endOfDay.getTime();

    return [startOfDayUnix, endOfDayUnix];
  }

  getMonthUnixTimestamps() {
    const now = new Date();

    // Get the start of the month
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
    const startOfMonthUnix = startOfMonth.getTime();

    // Get the end of the month
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    const endOfMonthUnix = endOfMonth.getTime();

    return [startOfMonthUnix, endOfMonthUnix];
  }

  async getParkingInsights(token) {
    let daily = this.getDayUnixTimestamps();
    let monthly = this.getMonthUnixTimestamps();

    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/' + daily[0] + '/' + daily[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ daily_parking_overview: response.data });

      const response_2 = await axios.get('https://api.enterprise-mobility.ch/parking_overview/' + monthly[0] + '/' + monthly[1], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ month_parking_overview: response_2.data });

    } catch (error) {
      console.error("Error fetching parking:", error);
    }
  }

  async getGeneralInsights(token) {
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/user_overview', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ general_insights_api: response.data });
    } catch (error) {
      console.error("Error fetching general insights:", error);
    }
  }

  async getParkingUsage(token, param) {
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/' + param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.setState({ graph_parking_usage: response.data });

    } catch (error) {
      console.error("Error fetching general insights:", error);
    }
  }

  async getSelectionGraph(eventKey) {
    const { getAccessTokenSilently } = this.props.auth0;

    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    });
    this.getParkingUsage(token, eventKey);
    this.setState({ graph_param: eventKey });
  }

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  async getOvUsage(token, param){

    let key_words = [];
    let str_adquisition_params='';

    if(param[0]){
      key_words.push('color')
      str_adquisition_params +='color'
    }
    if(param[1]){
      key_words.push('color_last_mile')
      if(str_adquisition_params===''){
        str_adquisition_params +='color_last_mile'
      } else{
        str_adquisition_params +=',color_last_mile'
      }
    }
    if(param[2]){
      key_words.push('color_working')
      if(str_adquisition_params===''){
        str_adquisition_params +='color_working'
      } else{
        str_adquisition_params +=',color_working'
      }
    }
    if(param[3]){
      key_words.push('color_bike')
      if(str_adquisition_params===''){
        str_adquisition_params +='color_bike'
      } else{
        str_adquisition_params +=',color_bike'
      }
    }

    try {
      const response = await axios.post('https://api.enterprise-mobility.ch/get_conversion_rates', { options: key_words }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      this.setState({ conversion_rates: response.data, adquisition_params: str_adquisition_params });

    } catch (error) {
      console.error("Error fetching conversion rates:", error);
    }
  }

  values_adquisition = async (event) => {
    let values = [];
    if(event.target.id==='color'){
      values = [event.target.checked, values_adquisition[1], values_adquisition[2], values_adquisition[3]]
    } else if(event.target.id==='color_last_mile'){
      values = [values_adquisition[0], event.target.checked, values_adquisition[2], values_adquisition[3]]
    } else if(event.target.id==='color_working'){
      values = [values_adquisition[0], values_adquisition[1], event.target.checked,  values_adquisition[3]]
    } else if(event.target.id==='color_bike'){
      values = [values_adquisition[0], values_adquisition[1], values_adquisition[2], event.target.checked]
    }
    values_adquisition = values;
    this.getOvUsage(this.state.token, values);
  }

  handleUpload = async () => {
    if (!this.state.file) {
      this.setState({ message: 'Please select a file' });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.state.file);

    reader.onload = async () => {
      const base64File = reader.result.split(',')[1];
      try {
        const response = await axios.post('https://api.enterprise-mobility.ch/sync_users', { file: base64File }, {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        });

        if (response.status === 200) {
          this.setState({ message: 'File uploaded successfully!' });
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error || 'Something went wrong.';
        this.setState({ message: `Error: ${errorMessage}` });
      }
    };

    reader.onerror = () => {
      this.setState({ message: 'Error reading file' });
    };
  };

  logoutWithRedirect = () => {
    const { logout } = this.props.auth0;
    logout({ returnTo: window.location.origin });
  };

  render() {
    setBodyColor({ color: "#edeae6" });

    const { user } = this.props.auth0 || {};
    const ExcelExportData = [{ "PersNr": '', "Postleitzahl": '' }];
    const general_insights_api = this.state.general_insights_api || {};
    const daily_parking_overview = this.state.daily_parking_overview || {};
    const month_parking_overview = this.state.month_parking_overview || {};
    const graph_parking_usage = this.state.graph_parking_usage || [];
    const conversion_rates = this.state.conversion_rates || [];

    const data = {
      labels: ['OSTWIND', 'ZVV-OSTWIND', 'Voucher'],
      datasets: [{
        label: '',
        data: [general_insights_api.OSTWIND, general_insights_api.ZPASS_ZVV_OSTWIND, general_insights_api.VOUCHER],
        backgroundColor: [
          'rgba(241,150,197, 0.5)',
          'rgba(153,92,254, 0.5)',
          'rgba(150,192,241, 0.5)'
        ],
        borderColor: [
          'rgb(241,150,197)',
          'rgb(153,92,254)',
          'rgb(150,192,241)'
        ],
        borderWidth: 1
      }]
    };

    const data_parking = {
      labels: graph_parking_usage.date,
      datasets: [
        {
          label: '1. Class Users',
          data: graph_parking_usage.number_class_1_users,
          backgroundColor: 'rgba(76,176,113, 0.5)',
          borderColor: 'rgb(76,176,113)',
          borderWidth: 1
        },
        {
          label: '2. Class Users',
          data: graph_parking_usage.number_class_2_users,
          backgroundColor: 'rgba(108,228,152, 0.5)',
          borderColor: 'rgb(108,228,152)',
          borderWidth: 1
        },
        {
          label: 'ParkingAbo Users',
          data: graph_parking_usage.number_parking_users,
          backgroundColor: 'rgba(229,110,109, 0.5)',
          borderColor: 'rgb(229,110,109)',
          borderWidth: 1
        },
        {
          label: 'Non-register or Undecided Users',
          data: graph_parking_usage.number_non_abo_users,
          backgroundColor: 'rgba(137,218,253, 0.5)',
          borderColor: 'rgb(137,218,253)',
          borderWidth: 1
        }
      ]
    };


    const data_pie = {
      labels: ['MobilityAbo', 'ParkingAbo', 'Undecided'],
      datasets: [{
        label: '',
        data: [general_insights_api.mobility_abo, general_insights_api.parking, (general_insights_api.activation_process_users + general_insights_api.missing_users)],
        backgroundColor: [
          'rgba(109,228,152, 0.5)',
          'rgba(229,110,109, 0.5)',
          'rgba(137,218,253, 0.5)'
        ],
        borderColor: [
          'rgb(109,228,152)',
          'rgb(229,110,109)',
          'rgb(137,218,253)'
        ],
        borderWidth: 1
      }]
    };

    const options = {
      plugins: {
        title: {
          display: true,
          text: 'MobilityAbo Type Distribution'
        },
        legend: {
          display: false
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    const options_parking = {
      plugins: {
        title: {
          display: false,
          text: 'Parking Usage'
        },
        legend: {
          display: true
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
          ticks: {
            maxTicksLimit: 12
          }
        },
        y: {
          stacked: true
        }
      }
    };

    const options_pie = {
      plugins: {
        title: {
          display: true,
          text: 'Current Modal Split (Adquisition)'
        },
        legend: {
          display: true
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    };

    return (
      <>
        <NavBar_MyMo />

        <Container className='container_main_background'>
          <Row>
            <Col xs={12} md={3}>
              <Card className="customCard" style={{ background: '#ff845c' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{numberWithCommas((general_insights_api.total_registered_users + general_insights_api.missing_users))} </Card.Title>
                  <Card.Subtitle>Total Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="customCard" style={{ background: '#89dafd' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{numberWithCommas((general_insights_api.activation_process_users + general_insights_api.missing_users))} </Card.Title>
                  <Card.Subtitle>Undecided Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="customCard" style={{ background: '#6ee598' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{numberWithCommas(general_insights_api.mobility_abo)} </Card.Title>
                  <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card className="customCard" style={{ background: '#e56e6e' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{numberWithCommas(general_insights_api.parking)} </Card.Title>
                  <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className='container_main_background'>
          <Row>
            <Col>
              <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <h1>MobilityAbo Insights</h1>
                <Row >
                  <Col sm={8}>
                    <Bar data={data} options={options} />
                  </Col>
                  <Col sm={4}>
                    <Pie data={data_pie} options={options_pie} />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        {
          user?.email?.split('@')[1].includes('42hacks') ?
          <Container className='container_main_background'>
          <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px', minHeight: '200px' }}>
            <Row>
              <Col md={8} sm={12}><h1>Adquisition Distribution</h1></Col>
              <Col md={4} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link to={"/map-adquisition/"+this.state.adquisition_params} style={{ textDecoration: 'none' }}>
              <Button variant="dark" id="button-addon2">
                  Explore in map
                </Button>
                </Link>
                </Col>
            </Row>
            <Row>
            <Form onClick={this.values_adquisition}>
            <Form.Check
            inline
            label="Status Quo"
            name="color"
            type="checkbox"
            id="color"
            defaultChecked={true}
            value={(values_adquisition[0]? 'yes': 'no')}
          />
          <Form.Check
            inline
            label="Last Mile"
            name="color_last_mile"
            type="checkbox"
            id="color_last_mile"
            value={(values_adquisition[1]? 'yes': 'no')}
          />
          <Form.Check
            inline
            label="Working Time"
            name="color_working"
            type="checkbox"
            id="color_working"
            value={(values_adquisition[2]? 'yes': 'no')}
          />
          <Form.Check
            inline
            label="Bike Potential"
            name="color_bike"
            type="checkbox"
            id="color_bike"
            value={(values_adquisition[3]? 'yes': 'no')}
          />
            </Form>
            </Row>
            <Row style={{ marginTop: '1%', marginBottom: '1%'}}>

              <Col style={{background: 'rgba(41, 118, 0, 1)', padding: '2%', marginBottom: '10px'}} md={4} sm={12}>
              <h5 style={{ color: 'white' }}>Green ÖV Quality</h5>
                <Row>
                  <Col style={{ paddingBottom: '10px'}}>
                    <Row style={{ paddingBottom: '10px'}}>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#e56e6d' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.green?.parking)} </Card.Title>
                            <Card.Subtitle>Parking</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#6de498' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.green?.class_2)} </Card.Title>
                            <Card.Subtitle>2. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#4CB071' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.green?.class_1)} </Card.Title>
                            <Card.Subtitle>1. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Card className="customCardParking" style={{ background: '#edeae6' }}>
                      <Card.Body>
                        <Card.Title>{(conversion_rates?.green?.conversion_rate*100).toFixed(0)}%</Card.Title>
                        <Card.Subtitle>Take Rate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col style={{background: 'rgba(203, 149, 0, 1)', padding: '2%', marginBottom: '10px'}} md={4} sm={12}>
              <h5 style={{ color: 'white' }}>Yellow ÖV Quality</h5>
                <Row>
                  <Col style={{ paddingBottom: '10px'}}>
                    <Row style={{ paddingBottom: '10px'}}>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#e56e6d' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.yellow?.parking)} </Card.Title>
                            <Card.Subtitle>Parking</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#6de498' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.yellow?.class_2)} </Card.Title>
                            <Card.Subtitle>2. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#4CB071' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.yellow?.class_1)} </Card.Title>
                            <Card.Subtitle>1. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Card className="customCardParking" style={{ background: '#edeae6' }}>
                      <Card.Body>
                      <Card.Title>{(conversion_rates?.yellow?.conversion_rate*100).toFixed(0)}%</Card.Title>
                      <Card.Subtitle>Take Rate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>

              <Col style={{background: 'rgba(150, 0, 0, 1)', padding: '2%', marginBottom: '10px'}} md={4} sm={12}>
              <h5 style={{ color: 'white' }}>Red ÖV Quality</h5>
                <Row>
                  <Col style={{ paddingBottom: '10px'}}>
                    <Row style={{ paddingBottom: '10px'}}>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#e56e6d' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.red?.parking)} </Card.Title>
                            <Card.Subtitle>Parking</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#6de498' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.red?.class_2)} </Card.Title>
                            <Card.Subtitle>2. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card className="customCardParking" style={{ background: '#4CB071' }}>
                          <Card.Body>
                            <Card.Title>{numberWithCommas(conversion_rates?.red?.class_1)} </Card.Title>
                            <Card.Subtitle>1. Class</Card.Subtitle>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Card className="customCardParking" style={{ background: '#edeae6' }}>
                      <Card.Body>
                      <Card.Title>{(conversion_rates?.red?.conversion_rate*100).toFixed(0)}%</Card.Title>
                        <Card.Subtitle>Take Rate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
          :
          <></>
        }

        <Container className='container_main_background'>
          <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
            <h1>Parking Insights</h1>
            <Row style={{ paddingBottom: '40px' }}>
              <h4>Monthly Usage</h4>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#bbb6b1' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.total_cars_entries)} </Card.Title>
                    <Card.Subtitle>Total Car Entries</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#ff845b' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.total_unique_plates)} </Card.Title>
                    <Card.Subtitle>Unique Car Entries</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#89dafd' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.number_non_abo_users - month_parking_overview.number_non_registered_cars)} </Card.Title>
                    <Card.Subtitle>Undecided Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#6de498' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.number_class_1_users + month_parking_overview.number_class_2_users)} </Card.Title>
                    <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#e56e6d' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.number_parking_users)} </Card.Title>
                    <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#edeae6' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(month_parking_overview.number_non_registered_cars)} </Card.Title>
                    <Card.Subtitle>Non-register Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <h4>Daily Usage</h4>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#bbb6b1' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.total_cars_entries)} </Card.Title>
                    <Card.Subtitle>Total Car Entries</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#ff845b' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.total_unique_plates)} </Card.Title>
                    <Card.Subtitle>Unique Car Entries</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#89dafd' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.number_non_abo_users - daily_parking_overview.number_non_registered_cars)} </Card.Title>
                    <Card.Subtitle>Undecided Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#6de498' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.number_class_1_users + daily_parking_overview.number_class_2_users)} </Card.Title>
                    <Card.Subtitle>MobilityAbo Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#e56e6d' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.number_parking_users)} </Card.Title>
                    <Card.Subtitle>ParkingAbo Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={2}>
                <Card className="customCardParking" style={{ background: '#edeae6' }}>
                  <Card.Body>
                    <Card.Title>{numberWithCommas(daily_parking_overview.number_non_registered_cars)} </Card.Title>
                    <Card.Subtitle>Non-register Users</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className='container_main_background'>
          <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px', minHeight: '500px' }}>
            <Row>
              <Col><h1>Parking Usage</h1></Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <DropdownButton
                  as={ButtonGroup}
                  key={'Secondary'}
                  id={`dropdown-variants-Secondary`}
                  variant={'dark'}
                  size="md"
                  title={'Last ' + this.state.graph_param}
                >
                  <Dropdown.Item eventKey="1" onClick={() => this.getSelectionGraph('week')}>Last week</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={() => this.getSelectionGraph('month')}>Last month</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={() => this.getSelectionGraph('year')}>Last year</Dropdown.Item>

                </DropdownButton></Col>
            </Row>
            <Bar data={data_parking} options={options_parking} />
          </Container>
        </Container>

        <Container className='container_main_background'>
          <Row>
            <Col>
              <Card className="customCard" style={{ background: '#89dafd' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{average(graph_parking_usage.number_non_abo_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>Undecided</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{ background: '#6ee598' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{average(graph_parking_usage.number_class_2_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>MobilityAbo</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="customCard" style={{ background: '#e56e6e' }}>
                <Card.Body>
                  <Card.Title style={{ fontSize: 60 }}>{average(graph_parking_usage.number_parking_users)?.toFixed(1)} </Card.Title>
                  <Card.Subtitle>ParkingAbo</Card.Subtitle>
                  <Card.Text>Average users parking per day</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className='container_main_background'>
          <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
            <h1>Upload Excel File</h1>
            <Row>
              <Form.Label>Please upload your HR Excel Data</Form.Label>
              <Col xs={12} md={8}>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" accept=".xlsx,.xls,.csv" onChange={this.handleFileChange} />
                </Form.Group>
              </Col>
              <Col xs={12} md={2}>
                <Button variant="dark" id="button-addon2" onClick={this.handleUpload}>
                  Upload file
                </Button>
              </Col>
              <Col xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ExportExcel excelData={ExcelExportData} fileName={'HR Data - Excel Export Template'} name={'Excel Template'}/>
              </Col>
            </Row>
            {this.state.message && <div className="mt-3 alert alert-info">{this.state.message}</div>}
          </Container>
        </Container>
      </>
    );
  }
}

export default withAuth0(Main);
