import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import setBodyColor from '../setBodyColor'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import NavBar_MyMo from "./components/NavBar";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import ExportExcel from "../utils/ExportExcel";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';


const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};


class ExcelExport extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    this.state = {
      token: null,
      mobilityabo_excel: [],
      mobilityabo_excel_oct: [],
      parking_deduction_excel: {},
      parking_deduction_excel_oct: {},
      parking_excel: [],
      parking_daily_excel: [],
      daily_licence_date: today
    };
  }


  async componentDidMount() {
    if (this.props.auth0) {
      const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.getAboOverview(token);
        this.getAboOverviewOct(token);
        this.getParkingOverview(token);
        this.getParkingSalaryOverviewOct(token);
        this.getParkingSalaryOverview(token);
        this.getDailyParkingOverview(token, this.state.daily_licence_date);
        this.setState({ token });
        
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }
  }

  unixToDate(unixMilliseconds) {
    const date = new Date(unixMilliseconds);
    
    // Extract day, month, and year from the date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
}

  async getAboOverview(token){
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/abo_overview/1723240800000/1726783199000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ mobilityabo_excel: response.data });

    } catch (error) {
      console.error("Error fetching september mobility excel:", error);
    }
  }

  async getAboOverviewOct(token){
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/abo_overview/1726783200000/1730415599000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ mobilityabo_excel_oct: response.data });

    } catch (error) {
      console.error("Error fetching october mobility excel:", error);
    }
  }

  async getParkingSalaryOverview(token){
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/1725141600000/1727733599000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ parking_deduction_excel: response.data });

    } catch (error) {
      console.error("Error fetching september parking salary excel:", error);
    }
  }

  async getParkingSalaryOverviewOct(token){
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/parking_overview/1727733600000/1730415599000', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ parking_deduction_excel_oct: response.data });

    } catch (error) {
      console.error("Error fetching october parking salary excel:", error);
    }
  }

  async getDailyParkingOverview(token, daily_licence_date){
    let date = new Date(daily_licence_date);
    // Get the Unix timestamp in milliseconds using the .getTime() method
    let date_unix = date.getTime();

    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/get_plates_for_day/'+date_unix, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ parking_daily_excel: response.data });

    } catch (error) {
      console.error("Error fetching parking_daily_excel:", error);
    }
  }

  async getParkingOverview(token){
    try {
      const response = await axios.get('https://api.enterprise-mobility.ch/get_register_plates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ parking_excel: response.data });

    } catch (error) {
      console.error("Error fetching get register plates:", error);
    }
  }

  getExcelParking(){
    var all = [];
		var json = this.state.parking_excel;
		if (json.length !== 0 || json.length !== 'undefined') {
            for (var i = 0; i < json.length; i++) {
                all.push({
                    "License plate": json[i].plate_number
                })
            }
        }
        return all;
  }

  getDailyParking(){
    var all = [];
		var json = this.state.parking_daily_excel;
		if (json.length !== 0 || json.length !== 'undefined') {
            for (var i = 0; i < json.length; i++) {
                all.push({
                    "License plate": json[i].plate_number
                })
            }
        }
        return all;
  }

  getExcelMobilityAboOct(){
    var all = [];
		var json = this.state.mobilityabo_excel_oct;
		if (json.length !== 0 || json.length !== 'undefined') {
            for (var i = 0; i < json.length; i++) {
                all.push({
                    "PersNr": json[i].PersNr,
                    "Activation Date (dd/mm/yyyy)": this.unixToDate(json[i].ActivationDate),
                    "ABO Type": (json[i].ABO==='class_2'? '2. Class': '1. Class'),
                    "Swisspass": json[i].Swisspass,
                    "Region": (json[i].region==='OSTWIND'? 'OSTWIND': 'ZVV OSTWIND'),
                    "Price": (json[i].ABO==='class_2'? '365': '1365'),
                })
            }
        }

        return all;
  }

  getExcelMobilityAbo(){
    var all = [];
		var json = this.state.mobilityabo_excel;
		if (json.length !== 0 || json.length !== 'undefined') {
            for (var i = 0; i < json.length; i++) {
                all.push({
                    "PersNr": json[i].PersNr,
                    "Activation Date (dd/mm/yyyy)": this.unixToDate(json[i].ActivationDate),
                    "ABO Type": (json[i].ABO==='class_2'? '2. Class': '1. Class'),
                    "Swisspass": json[i].Swisspass,
                    "Region": (json[i].region==='OSTWIND'? 'OSTWIND': 'ZVV OSTWIND'),
                    "Price": (json[i].ABO==='class_2'? '365': '1365'),
                })
            }
        }

        return all;
  }

  getExcelParkingSalaryExportDataOct(){
    var all = [];
		var json = this.state.parking_deduction_excel_oct?.registered_cars_employees;
    console.log(json)
		if (json?.length !== 0 || json?.length !== 'undefined') {
            for (var i = 0; i < json?.length; i++) {
              if(json[i].pers_nr!==0 && json[i].pers_nr!==1999){
                all.push({
                  "PersNr": json[i].pers_nr,
                  "ABO type": (json[i].state !=="⁠active_abo"? '-': (json[i].ABO==='class_2'? '2. Class': (json[i].ABO==='parking'? 'Parking': '1. Class'))),
                  "Car entries": json[i].car_entries,
                  "Mistake reported": json[i].mistakes_reported,
                  "Valid entries": json[i].valid_entries,
                  "Price": ((json[i].ABO==='parking'||json[i].state !=="⁠active_abo")? 0: (json[i].valid_entries*10)),
              })
              }
            }
        }

        return all;
  }

  getExcelParkingSalaryExportData(){
    var all = [];
		var json = this.state.parking_deduction_excel?.registered_cars_employees;
    console.log(json)
		if (json?.length !== 0 || json?.length !== 'undefined') {
            for (var i = 0; i < json?.length; i++) {
              if(json[i].pers_nr!==0 && json[i].pers_nr!==1999){
                all.push({
                  "PersNr": json[i].pers_nr,
                  "ABO type": (json[i].state !=="⁠active_abo"? '-': (json[i].ABO==='class_2'? '2. Class': (json[i].ABO==='parking'? 'Parking': '1. Class'))),
                  "Car entries": json[i].car_entries,
                  "Mistake reported": json[i].mistakes_reported,
                  "Valid entries": json[i].valid_entries,
                  "Price": ((json[i].ABO==='parking'||json[i].state !=="⁠active_abo")? 0: (json[i].valid_entries*10)),
              })
              }
            }
        }

        return all;
  }

  handleDateChange = (event) => {
    this.setState({
      daily_licence_date: event.target.value
    });

    this.getDailyParkingOverview(this.state.token, event.target.value);
  };

  render() {
    setBodyColor({color: "#edeae6"});
    const ExcelExportData = this.getExcelMobilityAbo();
    const ExcelExportDataOct = this.getExcelMobilityAboOct();
    const ExcelParkingSalaryExportData = this.getExcelParkingSalaryExportData();
    const ExcelParkingSalaryExportDataOct= this.getExcelParkingSalaryExportDataOct();
    const ExcelExportDataParking = this.getExcelParking();
    const ExcelExportDailyParking = this.getDailyParking();
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    return (
      <>
        <NavBar_MyMo />
        <Container className='container_main_background'>
        <Row>
            <Col>
              <Container className='container_main' style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <h1>Exports</h1>
                <Tabs
      defaultActiveKey="mobility_abo"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="mobility_abo" title="MobilityAbo">
        <h3>MobilityAbo Salary Deduction</h3>
        <Alert variant="secondary">
        <Alert.Heading>September 2024</Alert.Heading>
        <p>
         Billing period: August 15th 2024 to September 19th 2024
        </p>
        <hr />
        <div className="d-flex justify-content-end">
        {
           this.state.mobilityabo_excel.length > 0 ?
           <ExportExcel excelData={ExcelExportData} fileName={'MobilityAbo Salary Deduction 15.08.2024 - 20.09.2024'} name={'Download Excel'}/>
           :
<Button variant="secondary" disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button>
          }
        </div>
      </Alert>
      <Alert variant="secondary">
        <Alert.Heading>October 2024</Alert.Heading>
        <p>
         Billing period: September 20th 2024 to October 31st 2024
        </p>
        <hr />
        <div className="d-flex justify-content-end">
        {
           this.state.mobilityabo_excel_oct.length > 0 ?
           <ExportExcel excelData={ExcelExportDataOct} fileName={'MobilityAbo Salary Deduction 20.09.2024 - 31.10.2024'} name={'Download Excel'}/>
           :
<Button variant="secondary" disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button>
          }
        </div>
      </Alert>
      </Tab>
      <Tab eventKey="parking_usage" title="Parking Usage">
      <h3>Parking Usage Salary Deduction</h3>
      <Alert variant="secondary">
        <Alert.Heading>September 2024</Alert.Heading>
        <p>
         Billing period: September 1st 2024 to September 30th 2024
        </p>
        <hr />
        <div className="d-flex justify-content-end">
        {
           Object.keys(this.state.parking_deduction_excel).length > 0 ?
            <ExportExcel excelData={ExcelParkingSalaryExportData} fileName={'Parking Lot Salary Deduction 01.09.2024 - 30.09.2024'} name={'Download Excel'}/>
:
<Button variant="secondary" disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
         Loading...
      </Button>
          }
        </div>
      </Alert>
      <Alert variant="secondary">
        <Alert.Heading>October 2024</Alert.Heading>
        <p>
         Billing period: Ocotber 1st 2024 to October 31st 2024
        </p>
        <hr />
        <div className="d-flex justify-content-end">
        {
           Object.keys(this.state.parking_deduction_excel_oct).length > 0 ?
            <ExportExcel excelData={ExcelParkingSalaryExportDataOct} fileName={'Parking Lot Salary Deduction 01.10.2024 - 31.10.2024'} name={'Download Excel'}/>
:
<Button variant="secondary" disabled>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
         Loading...
      </Button>
          }
        </div>
      </Alert>
      </Tab>
      <Tab eventKey="license_plates" title="License Plates">
      <h3>License Plates</h3>
      <Alert variant="secondary">
      <Alert.Heading>Registered License Plates</Alert.Heading>
        <div className="d-flex justify-content-end">
        <ExportExcel excelData={ExcelExportDataParking} fileName={'Registered License Plates - '+new Date().toJSON().slice(0,10).replace(/-/g,'/')} name={'Download Excel'}/>
        </div>
      </Alert>
      <Alert variant="secondary">
      <Alert.Heading>Daily License Plates</Alert.Heading>
      <Row>
        <Col>
        <Form>
          <Form.Control type="date" value={this.state.daily_licence_date} max={today} onChange={this.handleDateChange}/>
          </Form>
        </Col>
        <Col>
        <div className="d-flex justify-content-end">
          <ExportExcel excelData={ExcelExportDailyParking} fileName={'Daily License Plates - '+this.state.daily_licence_date} name={'Download Excel'}/>
          </div>
        </Col>
      </Row>
      </Alert>
      </Tab>
    </Tabs>

              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withAuth0(ExcelExport);
